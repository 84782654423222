import React from "react";

// Core Components

//Scroll to Top
import ScrollToTop from "./ScrollToTop";
// react router dom
import { BrowserRouter, Switch, Route } from "react-router-dom";
const HomePage = React.lazy(() => import("./components/Homepage"));
const Talents = React.lazy(() => import("./components/pages/Talents"));
const TalentsDetails = React.lazy(() =>
  import("./components/pages/TalentDetails")
);
const Services = React.lazy(() => import("./components/pages/Services"));
const App = (props) => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <React.Suspense fallback={<p>Loading...</p>}>
          <Route path="/" exact render={(props) => <HomePage {...props} />} />
          <Route
            path="/all-creative-talent"
            render={(props) => <Talents {...props} />}
          />
          <Route
            path="/talents/:slug"
            exact
            render={(props) => <TalentsDetails {...props} />}
          />
          <Route
            path="/services"
            exact
            render={(props) => <Services {...props} />}
          />
        </React.Suspense>
      </Switch>
    </BrowserRouter>
  );
};
export default App;
