import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./scss/style.css";
import "./scss/responsive.css";
import App from "./App";
import { ParallaxProvider } from "react-scroll-parallax";
ReactDOM.render(
  <ParallaxProvider>
    <App />
  </ParallaxProvider>,
  document.getElementById("root")
);
